<script setup lang="ts">
import { type HTMLAttributes } from 'vue';
const props = defineProps<{ class?: HTMLAttributes['class'] }>();
</script>
<template>
    <Dialog open>
        <DialogContent :class="props.class">
            <LoadingSpinner :size="50"></LoadingSpinner>
        </DialogContent>
    </Dialog>
</template>
